@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

:root {
  --font-base: 'Lora', serif;

  --black-color: #170312;
  --primary-color: #E4C3AD;
  --secondary-color: #826251;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #483F32;
  --brown-color: #46364a;
  --white-color: #F5E9E0;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}